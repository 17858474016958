import { apiEgpmTaskDetail } from '@/request/egpmMaterialApi';
import { useRoute } from 'vue-router';
import { ref, onUnmounted } from 'vue';

export default () => {
    const route = useRoute();
    const task = ref({});
    let timer = null;
    const duration = 1000 * 60;

    async function getTaskDetails() {
        const { data } = await apiEgpmTaskDetail({ id: route.query.id });
        task.value = data || {};
        // 定时刷新任务状态
        if (task.value.taskState !== 1) {
            clearTimeout(timer);
            timer = null;
            return;
        }
        timer = setTimeout(getTaskDetails, duration);
    }

    getTaskDetails();

    onUnmounted(() => {
        clearTimeout(timer);
        timer = null;
    })

    return { task, getTaskDetails }
}