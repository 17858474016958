import { apiEgpmDownloadCreate } from '@/request/egpmMaterialApi';
import withLoading from '@/utils/loading';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';

export default () => {

    const router = useRouter();
    const route = useRoute();

    // 创建下载任务-全量
    async function handleDownload(taskId) {
        const { code } = await withLoading(apiEgpmDownloadCreate)({ taskId, flag: 2 })
        if (code !== '0') return;
        ElMessage.success('创建下载任务成功');
        const href = router.resolve({
            path: `/egpm/material/download`,
            query: { reportId: route.query.reportId },
        }).href;
        window.open(href, "_blank");
    }

    return { handleDownload }
}