<template>
    <el-dialog :model-value="show" title="提示" width="400px" @close="close" @open="open">
        <div class="add_again">
            <ul>
                <li>
                    <span>当前数据批次：</span><span>{{ task.minBatch }} - {{ task.maxBatch }}</span>
                </li>
                <li>
                    <span>最新数据批次：</span><span>{{ newBatch }}</span>
                </li>
            </ul>
            <el-radio-group v-model="inc">
                <el-row>
                    <el-radio :label="1">计算新增批次数据</el-radio>
                </el-row>
                <el-row style="display: flex; align-items: center">
                    <el-radio :label="0">全量计算数据到最新批次数据</el-radio>
                    <el-tooltip class="box-item" effect="dark" content="由于模型优化和参数配置调整，全量计算数据，可能导致历史批次数据结果变化" placement="bottom">
                        <InfoFilled class="icon" />
                    </el-tooltip>
                </el-row>
            </el-radio-group>
        </div>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="submit" :loading="loading">确认</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import { InfoFilled } from "@element-plus/icons-vue";
import { apiEgpmDictCode, apiEgpmTaskAgainInc } from "@/request/egpmMaterialApi";
import { useRoute } from "vue-router";

const route = useRoute();
defineProps({
    show: { type: Boolean, default: false },
    task: { type: Object, default: {} },
});
const emits = defineEmits(["update:show", "refreshTask"]);
const newBatch = ref("");
const inc = ref(1);
const loading = ref(false);

// 获取批次
async function getBatchs() {
    let { data } = await apiEgpmDictCode({ code: "batch_min", reportConfigId: route.query.id });
    data = data || [];
    // 因为批次排序不确定，找出最大的批次就是最新批次
    const vals = data.map((item) => item.value);
    const max = Math.max(...vals);
    newBatch.value = data.find((item) => item.value === max).dictValue;
}

// 提交
async function submit() {
    loading.value = true;
    try {
        const { code } = await apiEgpmTaskAgainInc({ batch: newBatch.value, inc: inc.value, taskId: route.query.id });
        if (code !== "0") return;
        close();
        emits("refreshTask");
    } finally {
        loading.value = false;
    }
}

// 打开
function open() {
    getBatchs();
}

// 关闭
function close() {
    loading.value = false;
    inc.value = 1;
    newBatch.value = "";
    emits("update:show", false);
}
</script>

<style scoped lang="scss">
.add_again {
    ul {
        margin-bottom: 20px;
        li {
            line-height: 30px;
        }
    }
    .el-radio {
        margin-right: 5px;
    }
    .icon {
        width: 1.2em;
        height: 1.2em;
        color: #333;
        cursor: pointer;
    }
}
</style>
