<template>
    <div class="container">
        <!-- 任务名称 -->
        <div class="row_box task_name">
            <div class="left">
                <div class="item">
                    <span>任务名称：</span><span>{{ task.taskName }}</span>
                </div>
                <div class="item">
                    <span>创建时间：</span><span>{{ task.createTime }}</span>
                </div>
                <el-tag type="info" size="large" v-if="task.taskState === 0">初始化</el-tag>
                <el-tag size="large" type="warning" v-if="task.taskState === 1">执行中</el-tag>
                <el-tag type="success" size="large" v-if="task.taskState === 2">已完成</el-tag>
                <el-tag type="error" size="large" v-if="task.taskState === 3">失败</el-tag>
            </div>
            <div class="right">
                <el-button @click="addAgainShow = true" v-if="task.taskState === 2">增量计算</el-button>
                <el-button type="primary" :icon="RefreshRight" v-if="task.taskState === 2 || task.taskState === 3" @click="handleAgain">重新执行</el-button>
                <el-button type="primary" :icon="Download" v-if="task.taskState === 2" @click="handleDownload($route.query.id)">导出全量数据</el-button>
            </div>
        </div>
        <!-- 数据 -->
        <div class="row_box" style="padding-top: 0">
            <div class="tab_bar">
                <li v-for="item in tabs" :key="item.path" :class="{ active: item.path === $route.matched[2]?.path }" @click="switchTab(item)">{{ item.name }}</li>
            </div>
            <router-view />
        </div>
    </div>
    <!-- 增量计算确认弹框 -->
    <AddAgainDialog v-model:show="addAgainShow" :task="task" @refreshTask="refreshTask" />
</template>

<script setup>
import { provide } from "vue";
import useTaskDetails from "./useTaskDetails";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { RefreshRight, Download } from "@element-plus/icons-vue";
import useAgain from "./again/useAgain";
import AddAgainDialog from "./again/AddAgainDialog.vue";
import useDownload from "./useDownload";

const route = useRoute();
const router = useRouter();
const tabs = [
    { name: "任务参数", path: "/egpm/material/details/params" },
    { name: "执行结果", path: "/egpm/material/details/result" },
];

// 任务详情
const { task, getTaskDetails } = useTaskDetails();

// 向子组件提供任务详情数据
provide("task", task);

// 增量计算/重新执行
const { addAgainShow, handleAgain, refreshTask } = useAgain({ getTaskDetails });

// 创建下载任务
const { handleDownload } = useDownload();

// tab切换
function switchTab(item) {
    if (item.name === "执行结果" && task.value.taskState !== 2) {
        ElMessage.error("任务未完成，不能查看执行结果");
        return;
    }
    router.replace({
        path: item.path,
        query: {
            reportId: route.query.reportId,
            id: route.query.id,
        },
    });
}
</script>

<style lang="scss" scoped>
.container {
    padding: 0 40px 40px 40px;
    .row_box {
        background: #fff;
        border-radius: 14px;
        padding: 20px;
        margin-bottom: 10px;
        .tit {
            padding-bottom: 12px;
            padding-top: 20px;
            border-bottom: 1px solid #f2f2f2;
            margin-bottom: 15px;
        }
    }
    .task_name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
            display: flex;
            align-items: center;
            .item {
                margin-right: 30px;
            }
        }
    }
    .tab_bar {
        display: flex;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #f2f2f2;
        li {
            line-height: 50px;
            margin-right: 30px;
            font-size: 16px;
            color: #666;
            cursor: pointer;
            padding: 0 12px;
            user-select: none;
            &.active {
                color: var(--primary-color);
                font-weight: bold;
                border-bottom: 2px solid var(--primary-color);
            }
        }
    }
}
</style>
